var Cameraland = Cameraland || {};

Cameraland.SearchAllLink = class {
    constructor(options) {
        this.options = $j.extend({}, {
            autocompleteLink: '#autocomplete-show-all',
            autocompleteForm: '#search_mini_form'
        }, options);

        this.showAllForm();
    }

    showAllForm() {
        const scrollButton = $j(this.options.autocompleteLink);

        scrollButton.on('click', (event) => {
            event.preventDefault();
            $j(this.options.autocompleteForm).submit();
            return true;
        });
    }
};

$j(document).ready(function () {
    new Cameraland.SearchAllLink();
});
